import React, { useState } from "react";
import "./Market.css";
import Crypto from "./Crypto/Crypto";
import Forex from "./Forex/Forex";
import CFD from "./CFD/CFD";
import Commodities from "./Commodities/Commodities";
import Stocks from "./Stocks/Stocks";
const Market = ({handleTradeChart}) => {
  const [marketShow, setMarketShow] = useState("Crypto");
  const handleMarketShow = (market) => {
    switch (market) {
      case "Crypto":
        setMarketShow("Crypto");
        break;
      case "Forex":
        setMarketShow("Forex");
        break;
      case "Stocks":
        setMarketShow("Stocks");
        break;
      case "Commodities":
        setMarketShow("Commodities");
        break;
      case "CFDs":
        setMarketShow("CFDs");
        break;

      default:
        break;
    }
  };
  return (
    <>
      <section className="dashboard-trade-market-section">
        <div className=" box-shadow ml-10 rounded-10 min-w-350 w-350">
          <div className="p-10 flex">
            <div className="base-input w-full">
              <i className="el-icon ml-16 mr-10" style={{ fontSize: "20px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path
                    fill="currentColor"
                    d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704z"
                  ></path>
                </svg>
              </i>
              <input className="pr-20" />
            </div>
            <div className="el-select trade-type ml-10">
              <div className="select-trigger el-tooltip__trigger el-tooltip__trigger">
                <div className="el-input el-input--suffix">
                  <div className="el-input__wrapper">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onClick={(e) => handleMarketShow(e?.target?.value)}
                    >
                      {/* <option selected disabled>Select</option> */}
                      <option defaultValue="Forex">Forex</option>
                      {/* <option defaultValue="Stocks">Stocks</option> */}
                      <option defaultValue="Commodities">Commodities</option>
                      {/* <option defaultValue="CFDs">CFDs</option> */}
                      <option defaultValue="Crypto" selected>Crypto</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {marketShow === "Crypto" && <Crypto handleTradeChart={handleTradeChart} />}
          {marketShow === "Forex" && <Forex  handleTradeChart={handleTradeChart} />}
          {marketShow === "Stocks" && <Stocks  handleTradeChart={handleTradeChart} />}
          {marketShow === "Commodities" && <Commodities  handleTradeChart={handleTradeChart} />}
          {marketShow === "CFDs" && <CFD  handleTradeChart={handleTradeChart} />}
        </div>
      </section>
    </>
  );
};

export default Market;
