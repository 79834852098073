import React, { useEffect, useState, useTransition } from "react";
import SingleItem from "./SingleItem";


const Crypto = ({ handleTradeChart }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [AllSymbol, setAllSymbol] = useState([])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);

        // if (data?.success) {
        //   const res = data?.data;
        //   const SymbolString = res.reduce(
        //     (acc, curr) => `${acc}${"XT."}${curr.Symbol}-${curr.currency},`,
        //     ""
        //   );
        //   const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
        //   setAllSymbol(Symbol);
        // }
      });
  }, []);


  if (loading) {
    return;
  }
  return (
    <>
      <div className="dashboard-trade-market-area  h-700 overflow-auto element">
        {data?.length !== 0 ? (
          data.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleTradeChart={handleTradeChart}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50">
              <h6> Data not found</h6>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Crypto;
