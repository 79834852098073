import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const Commodities = ({handleTradeChart}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/user/trade/market/commodities/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
              setData(data.data);
              setLoading(false);
              // if (data?.success) {
              //   const res = data?.data;
              //   const SymbolString = res.reduce(
              //     (acc, curr) => `${acc}${curr.symbol},`,
              //     ""
              //   );
              //   const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
              //   fetchData(Symbol);
              // }
            });


    }, [])

    
      const [livePrice, setLivePrice] = useState([]);
    

      // const fetchData = async (Symbol) => {
      //   if (Symbol?.length !== 0) {
      //     const data = await fetch(
      //       `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=${process.env.REACT_APP_TRADE_API_KEY}`
      //     );
      //     const apiResponse = await data.json();
      //     setLivePrice(apiResponse?.quotes);
      //     if (apiResponse?.quotes) {
      //       setLoading(false);
      //     }
      //   }
      // };

      
      if(loading){
        return;
      }
    return (
        <>
              <div className="dashboard-trade-market-area  h-700 overflow-auto element">
                
            {data?.length !== 0 ? (
                            data.map((data, index) => {
                              if (data) {
                                return (
                                  <SingleItem
                                    data={data}
                                    index={index}
                                    key={data._id}
                                    livePrice={livePrice}
                                    handleTradeChart={handleTradeChart}
                                  ></SingleItem>
                                );
                              }
                            })
                          ) : (
                            <>
                              <div className="text-center h-50">
                                <h6> Data not found</h6>
                              </div>
                            </>
                          )}

          </div> 
        </>
    );
};

export default Commodities;